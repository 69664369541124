<template>
  <b-card class="mb-0 account-overview-highlight">
    <p class="account-overview-item">
      <span v-text="$t('account')" />:
      <b v-if="agent.username">
        {{ agent.username }}
      </b>
      <b v-else>
        -
      </b>
    </p>
    <p class="account-overview-item">
      <span v-text="$t('role')" />:
      <b v-if="agent.role">
        {{ agent.role }}
      </b>
      <b v-else>
        -
      </b>
    </p>
    <p class="account-overview-item">
      <span v-text="$t('currency')" />:
      <span v-if="agent.currency">
        {{ agent.currency }}
      </span>
      <span v-else>
        -
      </span>
    </p>
    <p class="account-overview-item">
      <span v-text="$t('shares')" />:
      <span
        v-if="agent.share"
        v-text="`${agent.share}%`"
      />
      <span
        v-else
        v-text="`-`"
      />
    </p>
    <p class="account-overview-item">
      <span v-text="$t('commission')" />:
      <span
        v-if="agent.commission"
      >
        {{ agent.commission }}%
      </span>
      <span
        v-else
      >
        -
      </span>
    </p>
    <p class="account-overview-item">
      <span v-text="$t('own_balance')" />:
      <span v-if="agent.balance">
        {{ Number(agent.balance).toLocaleString() }}
      </span>
      <span v-else>
        -
      </span>
    </p>
    <p class="account-overview-item">
      <span v-text="$t('total_balance')" />:
      <span v-if="agent.total_balance">
        {{ Number(agent.total_balance).toLocaleString() }}
      </span>
      <span v-else>
        -
      </span>
    </p>
    <p class="account-overview-item">
      <span v-text="$t('account_status')" />:
      <span v-if="agent.status === 1">
        Enable
      </span>
      <span v-else>
        Disabled
      </span>
    </p>
  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue'

export default {
  components: {
    BCard,
  },
  props: {
    agent: {
      type: Object,
      default: () => [],
    },
  },
}
</script>
