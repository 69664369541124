<template>
  <div>
    <agent-overview
      :agent="agent"
    />
    <!-- Table Top -->
    <div class="m-2">
      <b-row>
        <b-col
          cols="12"
          md="5"
          lg="3"
        >
          <b-input-group class="mb-1-sm">
            <b-form-input
              v-model="filter.account"
              :placeholder="$t('account')"
              autocomplete="off"
            />
            <b-input-group-append>
              <b-button
                variant="outline-primary"
                @click="loadData(1)"
                v-text="$t('search')"
              />
            </b-input-group-append>
          </b-input-group>
        </b-col>
        <b-col
          cols="12"
          md="7"
          lg="9"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-button
              v-if="userData.can_edit"
              variant="primary"
              @click="createSubAccountModal"
            >
              {{ $t('create_account') }}
            </b-button>
          </div>
        </b-col>
      </b-row>
    </div>

    <!-- Table Container Card -->
    <b-overlay
      :show="loading"
      opacity="0.80"
      rounded="sm"
    >
      <b-card>
        <!-- table -->
        <vue-good-table
          :columns="columns"
          :rows="users"
          :pagination-options="{
            enabled: true,
            perPage:pageLength
          }"
        >
          <template
            slot="table-row"
            slot-scope="props"
          >
            <!-- Column: Edit -->
            <span
              v-if="props.column.field === 'edit'"
            >
              <b-button
                v-if="userData.can_edit"
                variant="outline-primary"
                size="sm"
                @click="editAccount(props.row.id)"
                v-text="$t('edit')"
              />
            </span>
            <span
              v-else-if="props.column.field === 'account_status'"
              v-text="$t(props.row.account_status)"
            />
            <span
              v-else-if="props.column.field === 'authority'"
              v-text="$t(props.row.authority)"
            />
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- Columns -->
          <template
            slot="table-column"
            slot-scope="props"
          >
            <span v-text="$t(props.column.label)" />
          </template>

          <!-- pagination -->
          <template
            slot="pagination-bottom"
            slot-scope="props"
          >
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-0">
                <span class="text-nowrap">
                  <span v-text="$t('pagination_showing')" /> 1 <span v-text="$t('pagination_to')" />
                </span>
                <b-form-select
                  v-model="pageLength"
                  :options="['50','100','200', '300']"
                  class="mx-1"
                  @input="(value)=>props.perPageChanged({currentPerPage:value})"
                />
                <span class="text-nowrap "> <span v-text="$t('pagination_of')" /> {{ props.total }}<span v-text="$t('pagination_entries')" /> </span>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-2"
                  @input="(value)=>props.pageChanged({currentPage:value})"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </b-card>
    </b-overlay>

    <!-- Create Modal -->
    <b-modal
      ref="create-sub-account-modal"
      size="lg"
      :title="$t('add_sub_account')"
      centered
      no-close-on-backdrop
      hide-footer
    >
      <b-form
        class="sub-account-modal"
        @submit.stop.prevent="createSubAccountHandleSubmit"
      >
        <b-row>
          <b-col
            cols="12"
            md="12"
            xl="12"
          >
            <statistic-card-vertical
              show-icon="false"
              :statistic="agent.username"
              :statistic-title="$t('main_account')"
            />
          </b-col>
        </b-row>
        <b-tabs
          pills
          align="center"
        >
          <b-tab
            :title="$t('sub_account_set_up')"
            active
          >
            <b-form-group
              label-cols="4"
              label-cols-lg="3"
              :label="$t('account')"
              label-for="create-sub-account-input-account"
            >
              <b-row>
                <b-col
                  cols="12"
                  md="12"
                  xl="7"
                >
                  <b-form-input
                    id="create-sub-account-input-account"
                    v-model="formData.account"
                    autocomplete="off"
                    maxlength="20"
                  />
                </b-col>
                <b-col
                  cols="12"
                  md="12"
                  xl="5"
                >
                  <span
                    class="child-requirements"
                    style="line-height: 38px;"
                  >
                    <span v-text="$t('create_modify_user_account_field')" />
                  </span>
                </b-col>
              </b-row>
            </b-form-group>
            <b-form-group
              label-cols="4"
              label-cols-lg="3"
              :label="$t('password')"
              label-for="create-sub-account-input-password"
            >
              <b-row>
                <b-col
                  cols="12"
                  md="12"
                  xl="7"
                >
                  <b-form-input
                    id="create-sub-account-input-password"
                    v-model="formData.password"
                    type="password"
                    autocomplete="off"
                    maxlength="20"
                  />
                </b-col>
                <b-col
                  cols="12"
                  md="12"
                  xl="5"
                >
                  <span class="child-requirements">
                    <span v-text="$t('create_modify_user_password_field_requirements_1')" /><br>
                    <span v-text="$t('create_modify_user_password_field_requirements_2')" />
                  </span>
                </b-col>
              </b-row>
            </b-form-group>
            <b-form-group
              label-cols="4"
              label-cols-lg="3"
              :label="$t('password_confirm')"
              label-for="create-sub-account-input-password-confirm"
            >
              <b-row>
                <b-col
                  cols="12"
                  md="12"
                  xl="7"
                >
                  <b-form-input
                    id="create-sub-account-input-password-confirm"
                    v-model="formData.password_confirmation"
                    type="password"
                    autocomplete="off"
                    maxlength="20"
                  />
                </b-col>
                <b-col
                  cols="12"
                  md="12"
                  xl="5"
                >
                  <span class="child-requirements">
                    <span v-text="$t('create_modify_user_password_field_requirements_1')" /><br>
                    <span v-text="$t('create_modify_user_password_field_requirements_2')" />
                  </span>
                </b-col>
              </b-row>
            </b-form-group>
            <b-form-group
              label-cols="4"
              label-cols-lg="3"
              :label="$t('contact_details')"
              label-for="create-sub-account-input-country-code"
            >
              <b-row>
                <b-col
                  cols="12"
                  md="12"
                  xl="7"
                  style="position:relative"
                >
                  <b-form-input
                    id="create-sub-account-input-country-code"
                    v-model="formData.country_code"
                    :placeholder="$t('country_code')"
                    style="width: 130px; float:left;"
                    autocomplete="off"
                  />
                  <span style="width:6px; float:left;line-height: 38px;margin-left: 2px; margin-right: 2px;">-</span>
                  <b-form-input
                    id="input-telephone-number"
                    v-model="formData.telephone_number"
                    :placeholder="$t('telephone_number')"
                    style="width: calc(100% - 140px); float:left;"
                    autocomplete="off"
                  />
                </b-col>
                <b-col
                  cols="12"
                  md="12"
                  xl="5"
                />
              </b-row>
            </b-form-group>
            <b-form-group
              label-cols="4"
              label-cols-lg="3"
              :label="$t('permissions')"
              style="text-align: right"
            >
              <b-row>
                <b-col
                  cols="12"
                  md="12"
                  xl="7"
                  style="position:relative"
                >
                  <div class="demo-inline-spacing">
                    <b-form-radio
                      v-model="formData.permission"
                      plain
                      name="permission"
                      :value="'View'"
                    >
                      <span v-text="$t('view')" />
                    </b-form-radio>
                    <b-form-radio
                      v-model="formData.permission"
                      plain
                      name="permission"
                      :value="'Edit'"
                    >
                      <span v-text="$t('edit')" />
                    </b-form-radio>
                  </div>
                </b-col>
                <b-col
                  cols="12"
                  md="12"
                  xl="5"
                />
              </b-row>
            </b-form-group>
            <b-form-group
              label-cols="4"
              label-cols-lg="3"
              :label="$t('account_status')"
              style="text-align: right"
            >
              <b-row>
                <b-col
                  cols="12"
                  md="12"
                  xl="7"
                  style="position:relative"
                >
                  <div class="demo-inline-spacing">
                    <b-form-radio
                      v-model="formData.agent_feature"
                      plain
                      name="agent_feature"
                      value="1"
                    >
                      <span v-text="$t('enable')" />
                    </b-form-radio>
                    <b-form-radio
                      v-model="formData.agent_feature"
                      plain
                      name="agent_feature"
                      value="0"
                    >
                      <span v-text="$t('disable')" />
                    </b-form-radio>
                  </div>
                </b-col>
                <b-col
                  cols="12"
                  md="12"
                  xl="5"
                />
              </b-row>
            </b-form-group>
          </b-tab>
        </b-tabs>
      </b-form>
      <footer
        class="modal-footer"
        style="margin: 0px -1.4rem"
      >
        <b-row style="width: 100%">
          <b-col
            cols="12"
            md="12"
            xl="12"
            style="text-align: center"
            class="mt-1"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-secondary"
              class="mr-1"
              @click="createSubAccountHandleCancel"
              v-text="$t('cancel')"
            />
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              @click="createSubAccountHandleSubmit"
              v-text="$t('enter')"
            />
          </b-col>
        </b-row>
      </footer>
    </b-modal>

    <!-- Edit Modal -->
    <b-modal
      ref="edit-sub-account-modal"
      size="lg"
      :title="$t('modify_sub_account')"
      centered
      no-close-on-backdrop
      hide-footer
    >
      <b-form
        class="sub-account-modal"
        @submit.stop.prevent="editSubAccountHandleSubmit"
      >
        <b-row>
          <b-col
            cols="12"
            md="12"
            xl="12"
          >
            <statistic-card-vertical
              show-icon="false"
              :statistic="agent.username"
              :statistic-title="$t('main_account')"
            />
          </b-col>
        </b-row>
        <b-tabs
          pills
          align="center"
        >
          <b-tab
            :title="$t('sub_account_set_up')"
            active
          >
            <b-form-group
              label-cols="4"
              label-cols-lg="3"
              :label="$t('account')"
              label-for="edit-sub-account-input-account"
            >
              <b-row>
                <b-col
                  cols="12"
                  md="12"
                  xl="7"
                >
                  <b-form-input
                    id="edit-sub-account-input-account"
                    v-model="formData.account"
                    autocomplete="off"
                    maxlength="20"
                    disabled
                  />
                </b-col>
                <b-col
                  cols="12"
                  md="12"
                  xl="5"
                >
                  <span
                    class="child-requirements"
                    style="line-height: 38px;"
                  >
                    <span v-text="$t('create_modify_user_account_field')" />
                  </span>
                </b-col>
              </b-row>
            </b-form-group>
            <b-form-group
              label-cols="4"
              label-cols-lg="3"
              :label="$t('password')"
              label-for="edit-sub-account-input-password"
            >
              <b-row>
                <b-col
                  cols="12"
                  md="12"
                  xl="7"
                >
                  <b-form-input
                    id="edit-sub-account-input-password"
                    v-model="formData.password"
                    type="password"
                    autocomplete="off"
                    maxlength="20"
                  />
                </b-col>
                <b-col
                  cols="12"
                  md="12"
                  xl="5"
                >
                  <span class="child-requirements">
                    <span v-text="$t('create_modify_user_password_field_requirements_1')" /><br>
                    <span v-text="$t('create_modify_user_password_field_requirements_2')" />
                  </span>
                </b-col>
              </b-row>
            </b-form-group>
            <b-form-group
              label-cols="4"
              label-cols-lg="3"
              :label="$t('password_confirm')"
              label-for="edit-sub-account-input-password-confirm"
            >
              <b-row>
                <b-col
                  cols="12"
                  md="12"
                  xl="7"
                >
                  <b-form-input
                    id="edit-sub-account-input-password-confirm"
                    v-model="formData.password_confirmation"
                    type="password"
                    autocomplete="off"
                    maxlength="20"
                  />
                </b-col>
                <b-col
                  cols="12"
                  md="12"
                  xl="5"
                >
                  <span class="child-requirements">
                    <span v-text="$t('create_modify_user_password_field_requirements_1')" /><br>
                    <span v-text="$t('create_modify_user_password_field_requirements_2')" />
                  </span>
                </b-col>
              </b-row>
            </b-form-group>
            <b-form-group
              label-cols="4"
              label-cols-lg="3"
              :label="$t('contact_details')"
              label-for="edit-sub-account-input-country-code"
            >
              <b-row>
                <b-col
                  cols="12"
                  md="12"
                  xl="7"
                  style="position:relative"
                >
                  <b-form-input
                    id="edit-sub-account-input-country-code"
                    v-model="formData.country_code"
                    :placeholder="$t('country_code')"
                    style="width: 130px; float:left;"
                    autocomplete="off"
                  />
                  <span style="width:6px; float:left;line-height: 38px;margin-left: 2px; margin-right: 2px;">-</span>
                  <b-form-input
                    id="edit-sub-account-input-telephone-number"
                    v-model="formData.telephone_number"
                    :placeholder="$t('telephone_number')"
                    style="width: calc(100% - 140px); float:left;"
                    autocomplete="off"
                  />
                </b-col>
                <b-col
                  cols="12"
                  md="12"
                  xl="5"
                />
              </b-row>
            </b-form-group>
            <b-form-group
              label-cols="4"
              label-cols-lg="3"
              :label="$t('permissions')"
              style="text-align: right"
            >
              <b-row>
                <b-col
                  cols="12"
                  md="12"
                  xl="7"
                  style="position:relative"
                >
                  <div class="demo-inline-spacing">
                    <b-form-radio
                      v-model="formData.permission"
                      plain
                      name="permission"
                      :value="'View'"
                    >
                      <span v-text="$t('view')" />
                    </b-form-radio>
                    <b-form-radio
                      v-model="formData.permission"
                      plain
                      name="permission"
                      :value="'Edit'"
                    >
                      <span v-text="$t('edit')" />
                    </b-form-radio>
                  </div>
                </b-col>
                <b-col
                  cols="12"
                  md="12"
                  xl="5"
                />
              </b-row>
            </b-form-group>
            <b-form-group
              label-cols="4"
              label-cols-lg="3"
              :label="$t('account_status')"
              style="text-align: right"
            >
              <b-row>
                <b-col
                  cols="12"
                  md="12"
                  xl="7"
                  style="position:relative"
                >
                  <div class="demo-inline-spacing">
                    <b-form-radio
                      v-model="formData.agent_feature"
                      plain
                      name="agent_feature"
                      value="1"
                    >
                      <span v-text="$t('enable')" />
                    </b-form-radio>
                    <b-form-radio
                      v-model="formData.agent_feature"
                      plain
                      name="agent_feature"
                      value="0"
                    >
                      <span v-text="$t('disable')" />
                    </b-form-radio>
                  </div>
                </b-col>
                <b-col
                  cols="12"
                  md="12"
                  xl="5"
                />
              </b-row>
            </b-form-group>
          </b-tab>
        </b-tabs>
      </b-form>
      <footer
        class="modal-footer"
        style="margin: 0px -1.4rem"
      >
        <b-row style="width: 100%">
          <b-col
            cols="12"
            md="12"
            xl="12"
            style="text-align: center"
            class="mt-1"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-secondary"
              class="mr-1"
              @click="editSubAccountHandleCancel"
              v-text="$t('cancel')"
            />
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              @click="editSubAccountHandleSubmit"
              v-text="$t('enter')"
            />
          </b-col>
        </b-row>
      </footer>
    </b-modal>
  </div>
</template>

<script>

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import StatisticCardVertical from '@/components/statistics-cards/StatisticCardVertical.vue'
import AgentOverview from '@/components/agent-overview/AgentOverview.vue'

import {
  BForm,
  BRow,
  BCol,
  BButton,
  BOverlay,
  BCard,
  BPagination,
  BModal,
  BTabs,
  BTab,
  BFormGroup,
  BFormSelect,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BFormRadio,
} from 'bootstrap-vue'
import Account from '@/models/Account'
import Ripple from 'vue-ripple-directive'

import 'vue-good-table/dist/vue-good-table.css'
import { VueGoodTable } from 'vue-good-table'

import { EventBus } from '../../../eventbus'

export default {
  name: 'SubAccounts',
  components: {
    StatisticCardVertical,
    AgentOverview,
    BForm,
    BRow,
    BCol,
    BButton,
    BOverlay,
    BCard,
    BPagination,
    BModal,
    BTabs,
    BTab,
    BFormGroup,
    BFormSelect,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BFormRadio,
    VueGoodTable,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      pageLength: 50,
      columns: [
        {
          label: 'account',
          field: 'sub_account',
          filterOptions: {
            enabled: false,
            placeholder: 'Search',
          },
        },
        {
          label: 'account_status',
          field: 'account_status',
          filterOptions: {
            enabled: false,
            placeholder: 'Search',
          },
        },
        {
          label: 'phone',
          field: 'phone',
          type: 'number',
          filterOptions: {
            enabled: false,
            placeholder: 'Search',
          },
        },
        {
          label: 'authority',
          field: 'authority',
          filterOptions: {
            enabled: false,
            placeholder: 'Search',
          },
        },
        {
          label: 'create_time',
          field: 'create_time',
          filterOptions: {
            enabled: false,
            placeholder: 'Search',
          },
        },
        {
          label: 'last_login',
          field: 'last_login',
          filterOptions: {
            enabled: false,
            placeholder: 'Search',
          },
        },
      ],
      accountTab: 0,
      users: [],
      loading: true,
      maxNickNameCount: 10,
      nickNameRemaining: 10,
      formData: {
        loading: false,
        id: '',
        account: '',
        password: '',
        password_confirmation: '',
        country_code: '',
        telephone_number: '',
        permission: 'view',
        agent_feature: 1,
      },
      agent: {
        username: '',
      },
      filter: {
        account: typeof this.$route.query.account !== 'undefined' ? this.$route.query.account : '',
        agent: typeof this.$route.query.agent !== 'undefined' ? this.$route.query.agent : '',
        page: typeof this.$route.query.page !== 'undefined' ? this.$route.query.page : 1,
      },
      agentLines: [],
      accountType: [],
    }
  },
  mounted() {
    this.loadData()
    if (this.userData.can_edit) {
      this.columns.push({
        label: 'edit',
        field: 'edit',
        sortable: false,
        filterOptions: {
          enabled: false,
          placeholder: 'Search',
        },
      })
    }
  },
  methods: {
    async loadData(page = 1) {
      this.loading = true
      const searchParams = {}
      Object.keys(this.filter).forEach(e => {
        if (typeof this.filter[e] === 'number') {
          if (this.filter[e] > 0) {
            searchParams[e] = this.filter[e]
          }
        } else if (typeof this.filter[e] === 'string') {
          if (this.filter[e] !== null && this.filter[e].length > 0) {
            searchParams[e] = this.filter[e]
          }
        }
      })
      searchParams.page = page
      this.$router.replace({ query: searchParams })
      const response = await Account.subAccountList(searchParams)
      this.users = response.data.data
      this.agent = response.data.meta.agent
      this.accountType = response.data.meta.options.account_types
      this.agentLines = response.data.meta.agent_lines
      EventBus.$emit('getMyUpdatedUserData') // Todo: Need to refactor
      this.loading = false
    },
    resetFormData() {
      this.formData = {
        loading: false,
        id: '',
        account: '',
        password: '',
        password_confirmation: '',
        country_code: '',
        telephone_number: '',
        permission: 'View',
        agent_feature: 1,
      }
    },
    createSubAccountModal() {
      this.resetFormData()
      this.$refs['create-sub-account-modal'].show()
    },
    createSubAccountHandleCancel() {
      this.$refs['create-sub-account-modal'].hide()
    },
    async createSubAccountHandleSubmit() {
      try {
        if (this.formData.loading === false) {
          this.formData.loading = true
          const response = await Account.storeSubAccount(this.formData)
          this.formData.loading = false
          if (response.data.status) {
            this.$swal({
              title: 'Success!',
              text: response.data.results.message,
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
            this.filter.account = ''
            await this.loadData(1)
            this.$refs['create-sub-account-modal'].hide()
            this.resetFormData()
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error',
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: response.data.results.message,
              },
            }, {
              position: 'top-center',
            })
            if (typeof response.data.results.tab !== 'undefined') {
              this.accountTab = response.data.results.tab
            }
            if (typeof response.data.results.field_id !== 'undefined') {
              document.getElementById(response.data.results.field_id).focus()
            }
          }
        }
      } catch (e) {
        this.formData.loading = false
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            icon: 'CoffeeIcon',
            variant: 'danger',
            text: e.message,
          },
        }, {
          position: 'top-center',
        })
      }
    },
    async editAccount(id) {
      this.resetFormData()
      const response = await Account.editSubAccount({ id })
      this.formData = response.data
      this.formData.loading = false
      this.$refs['edit-sub-account-modal'].show()
    },
    editSubAccountHandleCancel() {
      this.$refs['edit-sub-account-modal'].hide()
    },
    async editSubAccountHandleSubmit() {
      try {
        if (this.formData.loading === false) {
          this.formData.loading = true
          const response = await Account.updateSubAccount(this.formData)
          this.formData.loading = false
          if (response.data.status) {
            this.$swal({
              title: 'Success!',
              text: response.data.results.message,
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
            await this.loadData()
            this.$refs['edit-sub-account-modal'].hide()
            this.resetFormData()
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error',
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: response.data.results.message,
              },
            }, {
              position: 'top-center',
            })
            if (typeof response.data.results.tab !== 'undefined') {
              this.accountTab = response.data.results.tab
            }
            if (typeof response.data.results.field_id !== 'undefined') {
              document.getElementById(response.data.results.field_id).focus()
            }
          }
        }
      } catch (e) {
        this.formData.loading = false
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            icon: 'CoffeeIcon',
            variant: 'danger',
            text: e.message,
          },
        }, {
          position: 'top-center',
        })
      }
    },
  },
}
</script>

<style>
.child-requirements {
  color: red;
  font-size:11px;
  vertical-align: top;
}
.sub-account-modal .form-group > .col-form-label{
  text-align: right;
}
</style>
